import React, { useEffect, useState } from "react";

import "../styles/darkeningText.css";
import "../styles/eyeBlink.css";
import "../styles/linkStyles.css";
import "../styles/styles.css";
import "../styles/languageButton.css";

import eyeLogo from "../image/eyeLogo.jpg";

export const MainPage = () => {
    const eyeEmoji = String.fromCodePoint(0xd83d, 0xdc41);

    const textsEn = [
        "Hi, I'm Ekiari.",
        "I specialize in Full Stack development.",
        "My stack: Node.js, React, JavaScript, C#, Git, Linux, and Bash.",
        `by Ekiari ${eyeEmoji} 2024`,
    ];
    const textsRu = [
        "Привет, я Экиари.",
        "Занимаюсь Full Stack разработкой.",
        "Мой стэк: Node.js, React, javascript, c#, git, linux, bash.",
        `by Ekiari ${eyeEmoji} 2024`,
    ];

    const links = [
        { href: "https://github.com/ekiari", label: "GitHub" },
        {
            href: "https://discordapp.com/users/949624979673206894",
            label: "Discord",
        },
        { href: "https://twitter.com/ekiari_dev", label: "Twitter/X" },
        {
            href: "https://bsky.app/profile/ekiari.bsky.social",
            label: "BlueSky",
        },
    ];

    const [textIndex, setTextIndex] = useState(0);
    const [linkIndex, setLinkIndex] = useState(0);
    const [isEnglish, setIsEnglish] = useState(true);

    const texts = isEnglish ? textsEn : textsRu;

    const blinkEye = () => {
        const eye = document.getElementById("eye");
        if (eye) {
            eye.classList.add("blink_animation");
            setTimeout(() => {
                eye.classList.remove("blink_animation");
            }, 200);
        }
    };

    const changeText = () => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setLinkIndex((prevIndex) => (prevIndex + 1) % links.length);
    };

    const handlerClick = () => {
        blinkEye();
        changeText();
    };

    const toogleLanguage = () => {
        setIsEnglish((prevIsEnglish) => !prevIsEnglish);
    };

    useEffect(() => {
        const displayText = document.getElementById("description");
        const displayLink = document.getElementById("links");
        if (displayText && displayLink) {
            displayText.style.opacity = 0;
            displayLink.style.opacity = 0;
            setTimeout(() => {
                displayText.textContent = texts[textIndex];
                displayText.style.opacity = 1;
                displayLink.innerHTML = `<a href="${links[linkIndex].href}" target="_blank" rel="noopener noreferrer">${links[linkIndex].label}</a>`;
                displayLink.style.opacity = 1;
            }, 200);
        }
    });
    return (
        <div>
            <button className="toogle_language" onClick={toogleLanguage}>
                {isEnglish ? "Language: English" : "Язык: Руссий"}
            </button>
            <div className="main_content">
                <div className="descriptionText">
                    <p id="links">
                        <a
                            href="https://github.com/ekiari"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            GitHub
                        </a>
                    </p>
                </div>
                <div className="eye_container" onClick={handlerClick}>
                    <img src={eyeLogo} alt="eye logo" id="eye" />
                </div>
                <div className="descriptionText">
                    <p id="description">
                        Hi, I'm Ekiari, I'm a beginner programmer. Student.
                    </p>
                </div>
            </div>
        </div>
    );
};
